import { Route } from 'react-router-dom';
import {AvailableRoute} from '../../AvailableRoute';
import React from 'react';
import {baseUrl} from "../../../const/path";

const InitiativesList = React.lazy(() => import('../SberBoostProject/InitiativesList').then(({ InitiativeList }) => ({ default: InitiativeList })));
const SberboostHomePage = React.lazy(() => import('../SberBoostProject/SberboostHomePage').then(({ SberboostHomePage }) => ({ default: SberboostHomePage })));
const InitiativeCreatePageController = React.lazy(() => import('../SberBoostProject/InitiativeCreatePageController').then( ({InitiativeCreatePageController}) => ({default: InitiativeCreatePageController})));
// const InitiativeCreatePage = React.lazy(() => import('../SberBoostProject/InitiativeCreatePageController/InitiativeCreatePage').then( ({InitiativeCreatePage}) => ({default: InitiativeCreatePage})));
// const InitiativeCreatePageNew = React.lazy(() => import('../SberBoostProject/InitiativeCreatePageController/InitiativeCreatePageNew').then( ({InitiativeCreatePageNew}) => ({default: InitiativeCreatePageNew})));
const InitiativeView = React.lazy(() => import('../SberBoostProject/InitiativeView').then(({ InitiativeView }) => ({ default: InitiativeView })));
const InitiativeLandingPage = React.lazy(() => import('../SberBoostProject/InitiativeLandingPage').then( ({InitiativeLandingPage}) => ({default: InitiativeLandingPage})));
const InitiativeDashboard = React.lazy(() => import('../SberBoostProject/InitiativeDashboard').then( ({InitiativeDashboard}) => ({default: InitiativeDashboard})));
const SberBoostRules = React.lazy(() => import('../SberBoostProject/SberBoostRules').then(({SberBoostRules}) => ({default: SberBoostRules})));
// const InitiativeExpertize = React.lazy(() => import('../SberBoostProject/InitiativeView/InitiativeViewBodyContentArea/InitiativeViewBodyContent/InitiativeExpertize').then(({ InitiativeExpertize }) => ({ default: InitiativeExpertize })));
const ParticipantsInstruction = React.lazy(() => import('../SberBoostProject/InitiativeLandingPage/Instructions/ParticipantInstruction').then(({ParticipantsInstruction}) => ({default: ParticipantsInstruction})));
const OwnerInstruction = React.lazy(() => import('../SberBoostProject/InitiativeLandingPage/Instructions/OwnerInstruction').then(({OwnerInstruction}) => ({default: OwnerInstruction})));
const DPSSInstruction = React.lazy(() => import('../SberBoostProject/InitiativeLandingPage/Instructions/DPSSInstruction').then(({DPSSInstruction}) => ({default: DPSSInstruction})));
const EditProjectLocal = React.lazy(() => import('../EditProject').then(({ EditProject }) => ({ default: EditProject })));
const NewLanding = React.lazy(() => import('../SberBoostProject/NewLanding').then(({NewLanding}) => ({default: NewLanding})));

/**
 * делаем пока пути спецпроектов прям из корня
 * если будет нужно, то спрячем их внутрь чего-нибудь
 */
const ProjectsSpecRoutes = (
  <>
    <Route path={`${baseUrl}`} element={<AvailableRoute moduleName="projects"/>}>
      <Route index element={<SberboostHomePage/>}/>
      <Route path=':initiativeId'>
        <Route index element={<InitiativeView/>}/>
      </Route>

      <Route path="create" element={<InitiativeCreatePageController/>}>
        <Route path=":draftId" element={<InitiativeCreatePageController/>}/>
      </Route>


      <Route path="edit" element={<EditProjectLocal/>}/>
      <Route path="main" element={<NewLanding/>}/>
      <Route path="SberBoostRules" element={<SberBoostRules/>}/>
      <Route path="instruction" element={<ParticipantsInstruction/>}/>
      <Route path="instructionOwner" element={<OwnerInstruction/>}/>
      <Route path="instructionDPSS" element={<DPSSInstruction/>}/>
    </Route>
    <Route>
      <Route path="dashboard-sberboost" element={<InitiativeDashboard/>}/>
    </Route>
  </>
);

export { ProjectsSpecRoutes }